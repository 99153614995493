
import {Component, Vue, Ref} from 'vue-property-decorator'
import {nanoid} from 'nanoid'

function getRandomInt (min: number, max: number) {
  const _min = Math.ceil(min)
  const _max = Math.floor(max)

  return Math.floor(Math.random() * (_max - _min + 1)) + _min
}

const MAX_EXT_DATA_ID_CHARS = 64



@Component({})
export default class Sber extends Vue {
  @Ref('container') readonly container: HTMLDivElement

  key = '079fcd94-b845-4d8c-ae00-387bfba1b5c7'
  widgetId = 'kvk'
  partnerId = 'petrolplusWidget'
  orderNumber = Number('' + Date.now() + getRandomInt(1000, 9999))
  orderData = {
    extDataId: nanoid(MAX_EXT_DATA_ID_CHARS),
    orderNumber: this.orderNumber,
    amount: '10000',
    isPartPayment: true,
    purpose: `Оплата заказа ${this.orderNumber}`,
    name: 'ООО Организация партнера',
    accountData: {
      accountNumber: '40702810838000401991',
      inn: '7743036465',
      kpp: '774301001',
      corrAccount: '30101810400000000225',
      bic: '044525225',
      nameBank: 'ПАО Сбербанк'
    },
    vatData: {
      amount: '10',
      calcMethod: 'ONTOP',
      rate: 20
    }
  }

  mounted () {
    const scr1 = document.createElement('script')
    scr1.setAttribute('src', 'https://widgetecom.ru/widgets-sdk.js')
    document.head.appendChild(scr1)

    scr1.onload = () => {
      const scr2 = document.createElement('script')
      scr2.setAttribute('src', 'https://storage.yandexcloud.net/sravni-affiliates-public/adblock/adblock_checker.js')
      document.head.appendChild(scr2)

      scr2.onload = () => {
        // @ts-ignore
        window.SberWidgetsSDK.create({
          widgetId: this.widgetId,
          key: this.key,
          partnerId: this.partnerId,
          container: this.container,
          orderData: this.orderData,
        })
      }
    }
  }
}
